<template>
  <div>
    <PageHeader />
    <b-overlay :show="isFetching">
      <b-card class="mb-4">
        <ValidationObserver ref="boxForm" v-slot="{ handleSubmit }">
          <form @submit.prevent="handleSubmit(onSubmit)">
            <AgentsSelectInput
              :value="boxForm.agentId"
              :readonly="boxId ? true : false"
              @update="onAgentIdChange"
            />
            <!-- <b-form-group
              :label="$t('fields.status')"
              label-for="status"
              label-cols-sm="12"
              label-cols-lg="3"
            >
              <b-form-radio-group
                id="status"
                v-model="boxForm.isActive"
                name="status"
              >
                <b-form-radio :value="true">
                  {{ $t('utils.status.enable') }}
                </b-form-radio>
                <b-form-radio :value="false">
                  {{ $t('utils.status.disable') }}
                </b-form-radio>
              </b-form-radio-group>
            </b-form-group> -->
            <b-form-group
              :label="$t('rewards.use_coupon')"
              label-for="userCoupon"
              label-cols-sm="12"
              label-cols-lg="3"
            >
              <b-input
                id="userCoupon"
                v-model="boxForm.requiredCoupons"
                type="number"
              >
              </b-input>
            </b-form-group>
            <b-form-group
              :label="$t('rewards.range_date')"
              label-for="start_date"
              label-cols-sm="12"
              label-cols-lg="3"
            >
              <b-row>
                <b-col>
                  <b-input-group :prepend="$t('rewards.start_date')">
                    <b-form-input
                      id="start_date"
                      v-model="boxForm.startDate"
                      v-mask="'####/##/##'"
                      placeholder="YYYY/MM/DD (01/01/2021)"
                    ></b-form-input>
                  </b-input-group>
                </b-col>
                <b-col>
                  <b-input-group :prepend="$t('rewards.end_date')">
                    <b-form-input
                      id="end_date"
                      v-model="boxForm.endDate"
                      v-mask="'####/##/##'"
                      placeholder="YYYY/MM/DD (01/01/2021)"
                    ></b-form-input>
                  </b-input-group>
                </b-col>
              </b-row>
            </b-form-group>
            <b-form-group
              :label="$t('fields.title')"
              label-for="boxTitle"
              label-cols-sm="12"
              label-cols-lg="3"
            >
              <b-row>
                <b-col>
                  <b-input id="boxTitle" v-model="boxForm.title"> </b-input>
                </b-col>
                <b-col cols="3">
                  <b-input v-model="boxDetail.rewardGameType" readonly />
                </b-col>
              </b-row>
            </b-form-group>

            <b-form-group
              :label="$t('fields.description')"
              label-cols-sm="12"
              label-cols-lg="3"
            >
              <vue-editor v-model="boxForm.description"></vue-editor>
            </b-form-group>
            <b-form-group
              :label="$t('fields.image')"
              label-for="imageURL"
              label-cols-sm="12"
              label-cols-lg="3"
            >
              <b-form-file id="imageURL" v-model="file" accept="image/*">
              </b-form-file>
              <b-form-text>
                <b-link v-if="file" class="text-danger" @click="file = ''">
                  {{ $t('buttons.remove') }}
                </b-link>
              </b-form-text>
            </b-form-group>
            <div class="text-right">
              <b-button variant="light" to="/rewards/list" class="mr-2">
                {{ $t('buttons.cancel') }}
              </b-button>
              <b-overlay
                :show="isCreating || isUpdating"
                rounded
                spinner-small
                class="d-inline-block"
              >
                <b-button type="submit" variant="primary">
                  {{ $t('buttons.confirm') }}
                </b-button>
              </b-overlay>
            </div>
          </form>
        </ValidationObserver>
      </b-card>

      <b-card v-if="boxId" class="mb-4">
        <h5>{{ $t('rewards.random_rewards') }}</h5>
        <template  v-for="reward in boxDetail.rewards" >
          <RewardForm :id="reward.id" :key="reward.id" :agent-id="agentId" :box-id="boxId" :value="reward"/>
        </template>
        <hr />
        <RewardForm :agent-id="agentId" :box-id="boxId"/>
      </b-card>
    </b-overlay>
  </div>
</template>

<script>
import { VueEditor } from 'vue2-editor'
import { mapActions, mapGetters, mapState } from 'vuex'

export default {
  page: {
    title: 'Reward random detail',
  },
  components: {
    VueEditor,
    AgentsSelectInput: () =>
      import('../../../components/agents/agents-select-input'),
    RewardForm: () => import('./components/RewardForm.vue'),
  },
  data() {
    return {
      file: null,
      typeOptions: [
        { text: this.$t('fields.amount'), value: 'AMOUNT' },
        { text: this.$t('fields.percentage'), value: 'PERCENT' },
      ],
      boxForm: {
        startDate: '',
        endDate: '',
        agentId: null,
        isActive: false,
        title: '',
        description: '',
        imageURL: '',
        requiredCoupons: 0,
        rewards: [],
      },
    }
  },
  computed: {
    ...mapState({
      isFetching: (state) => state.reward.isFetchingBox,
      isCreating: (state) => state.user.isCreatingBox,
      isUpdating: (state) => state.user.isUpdatingBox,
    }),
    ...mapGetters(['boxDetail', 'userInfo', 'isMaster']),
    boxId() {
      return this.$route.params.id === 'create' ? null : this.$route.params.id
    },
    agentId() {
      return this.$route.query.agentId || this.userInfo.agentId
    },
  },
  watch: {
    boxDetail(val) {
      if (val) {
        this.setData()
      }
    },
  },
  created() {
    if (this.boxId) {
      this.fetchBox({
        agentId: this.agentId,
        id: this.boxId,
      })
    }
  },
  methods: {
    ...mapActions([
      'fetchBox',
      'createBox',
      'updateBox',
    ]),
    setData() {
      if (this.boxDetail) {
        const data = JSON.parse(JSON.stringify(this.boxDetail))
        this.boxForm = { ...this.boxForm, ...data }
        if (this.agentId) {
          this.boxForm.agentId = this.agentId
        }
      } else {
        this.boxForm = {
          startDate: '',
        endDate: '',
        agentId: null,
        isActive: false,
        title: '',
        description: '',
        imageURL: '',
        requiredCoupons: 0,
        rewards: [],
        }
      }
    },
    onSubmit() {
      if (this.boxForm.id) {
        this.updateBox({
          agentId: this.boxForm.agentId,
          id: this.boxForm.id,
          data: this.boxForm,
          file: this.file,
        })
      } else {
        this.createBox({
          agentId: this.boxForm.agentId,
          data: this.boxForm,
          file: this.file,
        })
      }
    },
    onAgentIdChange(agentId) {
      this.boxForm.agentId = agentId
    },
  },
}
</script>
